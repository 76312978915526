import { forwardRef } from "react";
import styled from "styled-components";
import { headers } from "../../../common/styles/RocketColours";

interface LabelHeadingProps {
  hasError: boolean;
}

export interface CheckboxButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  testid: string;
  label: string;
  description?: string;
  hasError: boolean;
  name: string;
  checked: boolean;
}

const StyledLabel = styled.label`
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-left: 26px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    box-shadow: 0 0 0 1px #898d8e;
    display: inline-block;
    top: 2px;
    left: 0;
    background-color: white;
    transition: box-shadow 0.2s;
  }

  &:hover:before,
  .checkbox-input:focus + &:before {
    box-shadow: 0 0 0 3px ${headers.black900};
  }

  &:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #6e6e6e;
    display: none;
    top: 5px;
    left: 3px;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  .checkbox-input:checked + &:after {
    display: inline-block;
  }

  .checkbox-input:active + &:after {
    background-color: ${headers.black900};
  }
`;

const StyledLabelHeading = styled.span<LabelHeadingProps>`
  color: ${(props) => (props.hasError ? headers.error : "inherit")};
  font-weight: ${(props) => (props.hasError ? 600 : "inherit")};
`;

const StyledInput = styled.input`
  position: absolute;
  width: 0;
  opacity: 0;
`;

const CheckboxButtonDescription = styled.p`
  padding-left: 3px;
  font-size: 14px;
  color: black;
`;

export const MarketingPreferencesCheckbox = forwardRef<
  any,
  CheckboxButtonProps
>((inputProps, ref) => {
  const { id, testid, label, description, hasError } = inputProps;
  return (
    <>
      <StyledInput
        {...inputProps}
        id={id}
        data-testid={testid}
        ref={ref}
        className="checkbox-input"
        type="checkbox"
      />
      <StyledLabel htmlFor={id}>
        <StyledLabelHeading hasError={hasError}>{label}</StyledLabelHeading>
        {description ?? (
          <CheckboxButtonDescription>{description}</CheckboxButtonDescription>
        )}
      </StyledLabel>
    </>
  );
});
