import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "../../styled/Layout";
import AppFooter from "../../../common/AppFooter";
import { RootState } from "../../../store/Store";
import { StyledButtonWrapper } from "../../styled/StyledButtonWrapper";
import { ThemedButton } from "ccp-common-ui-components";

import { SubPageHeader } from "../../styled/SubPageHeader";
import { PageLoader } from "../../common/PageLoader";
import { IconCircle } from "../../common/IconCircle";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoPageThemedButton,
} from "../../styled/InfoPage";
import {
  CancelLink,
  MarketingPreferencesContainer,
  MarketingPreferencesDescription,
  MarketingPreferencesButtons,
} from "./MarketingPreferencesPageStyles";
import { MarketingPreferencesCheckbox } from "./MarketingPreferencesCheckbox";
import { getCustomerProfilePreferencesAction } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import { MarketingPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import { redirect } from "../../../common/utils/NavigationHelpers";
import { updateCustomerProfilePreferences } from "../../../api/preferences/CustomerProfilePreferencesApi";
import { mapPreferences } from "../Utils/PreferencesUtils";

const getBrandSpecificDescriptor = (brand?: string) => {
  switch (brand) {
    case "cexp":
      return "Coles Express";
    case "supermarket":
      return "Coles Supermarkets";
    default:
      return "Coles Supermarkets";
  }
};

const generateModeDescription = (mode: string) => {
  switch (mode) {
    case "sms":
      return "SMS";
    default:
      return `${mode[0].toUpperCase()}${mode.slice(1)}`;
  }
};

const MarketingPreferencesPage = () => {
  const history = useHistory();

  const [editablePreferences, setEditablePreferences] = useState<
    MarketingPreference[]
  >([]);
  const [isMarketingPreferencesChanged, setMarketingPreferencesStatus] =
    useState<boolean>(false);
  const [updatePreferencesSuccess, setUpdatePreferencesSuccess] =
    useState<boolean>(false);
  const [updatePreferencesFailed, setUpdatePreferencesFailed] =
    useState<boolean>(false);
  const [currentlySelectedCount, setCurrentlySelectedCount] =
    useState<number>(0);
  const { brand } = useParams<{ brand?: string }>();
  const dispatch = useDispatch();
  const { marketingPreferences, isLoadingPreferences } = useSelector(
    (state: RootState) => state.customerProfilePreferences
  );

  const showLoader = isLoadingPreferences;

  useEffect(() => {
    setEditablePreferences(
      marketingPreferences.filter((pref) => pref.brand === brand)
    );
  }, [marketingPreferences, brand]);

  useEffect(() => {
    dispatch(getCustomerProfilePreferencesAction());
  }, [dispatch]);

  useEffect(() => {
    const numSelected = editablePreferences.filter(
      (p) => p.value === true
    ).length;
    setCurrentlySelectedCount(numSelected);
  }, [editablePreferences]);

  const changeHandler = (pref: MarketingPreference) => {
    const clonedEditablePreferences: MarketingPreference[] = JSON.parse(
      JSON.stringify(editablePreferences)
    );
    const targetItemIndex = clonedEditablePreferences.findIndex(
      (i) => i.name === pref.name
    );
    if (targetItemIndex !== -1) {
      clonedEditablePreferences[targetItemIndex].value = !pref.value;
      setEditablePreferences(clonedEditablePreferences);
      setMarketingPreferencesStatus(true);
    }
  };

  const selectUnselectAllHandler = () => {
    const clonedEditablePreferences: MarketingPreference[] = JSON.parse(
      JSON.stringify(editablePreferences)
    );
    clonedEditablePreferences.map((p) => (p.value = false));
    setEditablePreferences(clonedEditablePreferences);
    setMarketingPreferencesStatus(true);
  };

  const handleSuccessOrErrorClick = () => {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  };

  const submitChangedPreferences = async () => {
    const payload = mapPreferences([
      ...(isMarketingPreferencesChanged ? editablePreferences : []),
    ]);

    const res = await updateCustomerProfilePreferences(payload);
    if (res) {
      setUpdatePreferencesSuccess(true);
      setUpdatePreferencesFailed(false);
    } else {
      setUpdatePreferencesSuccess(false);
      setUpdatePreferencesFailed(true);
    }
  };

  const updateSuccessMessage = (
    <>
      <IconCircle decorative={true} />
      <CenteredHeader tabIndex={-1}>
        Marketing preferences updated
      </CenteredHeader>
      <CenteredParagraph>
        Your marketing preferences have been updated successfully.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const updateErrorMessage = (
    <>
      <IconCircle decorative={true} hasError={true} />
      <CenteredHeader hasError={true} tabIndex={-1}>
        Marketing preferences not changed
      </CenteredHeader>
      <CenteredParagraph>
        An error has occurred. Your marketing preferences have not been updated.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  return (
    <Layout>
      <SubPageHeader>{`Manage my ${getBrandSpecificDescriptor(
        brand
      )} marketing preferences`}</SubPageHeader>
      <PageLoader isShown={showLoader} />
      {!showLoader && (
        <MarketingPreferencesContainer>
          {updatePreferencesSuccess &&
            !updatePreferencesFailed &&
            updateSuccessMessage}
          {!updatePreferencesSuccess &&
            updatePreferencesFailed &&
            updateErrorMessage}
          {!(updatePreferencesSuccess || updatePreferencesFailed) && (
            <>
              <MarketingPreferencesDescription>
                Send me {getBrandSpecificDescriptor(brand)} marketing
                communications
              </MarketingPreferencesDescription>
              {editablePreferences &&
                editablePreferences.length &&
                editablePreferences.length > 0 &&
                editablePreferences.map((pref) => (
                  <MarketingPreferencesCheckbox
                    key={pref.name}
                    id={pref.name}
                    name={pref.name}
                    testid={`test-${pref.name}`}
                    label={generateModeDescription(pref.mode)}
                    hasError={false}
                    checked={pref.value}
                    onChange={() => {
                      changeHandler(pref);
                    }}
                  />
                ))}
              <MarketingPreferencesButtons>
                <MarketingPreferencesCheckbox
                  id="select-unselect-all"
                  name="select-unselect-all"
                  testid="test-select-unselect-all"
                  label={`Unsubscribe from all marketing communications from ${getBrandSpecificDescriptor(
                    brand
                  )}`}
                  hasError={false}
                  checked={currentlySelectedCount === 0}
                  onChange={() => {
                    selectUnselectAllHandler();
                  }}
                />
                <p>
                  If you unsubscribe, you'll still receive non-marketing
                  communications relating to your account and purchases.
                </p>
                <ThemedButton
                  aria-label="Save preference for marketing communications"
                  data-testid="save-preferences"
                  onClick={() => {
                    submitChangedPreferences();
                  }}
                >
                  Save preferences
                </ThemedButton>
                <CancelLink tabIndex={0} href={getReturnToLink()}>
                  Cancel
                </CancelLink>
              </MarketingPreferencesButtons>
            </>
          )}
        </MarketingPreferencesContainer>
      )}
      <AppFooter />
    </Layout>
  );
};

export default MarketingPreferencesPage;
