import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "../../styled/Layout";
import AppFooter from "../../../common/AppFooter";
import { RootState } from "../../../store/Store";
import { StyledButtonWrapper } from "../../styled/StyledButtonWrapper";
import { ThemedButton, InputFieldNew } from "ccp-common-ui-components";
import { useForm } from "react-hook-form";

import { SubPageHeader } from "../../styled/SubPageHeader";
import { PageLoader } from "../../common/PageLoader";
import { IconCircle } from "../../common/IconCircle";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoPageThemedButton,
} from "../../styled/InfoPage";
import {
  CancelLink,
  ReceiptPreferencesContainer,
  ReceiptPreferencesDescriptionBold,
  ReceiptPreferencesDescription,
  ReceiptPreferencesButtons,
  Divider,
  ReceiptPreferencesDescriptionBoldLvl2,
} from "./ReceiptPreferencesPageStyles";
import { ReceiptPreferencesCheckbox } from "./ReceiptPreferencesCheckbox";
import { getCustomerProfilePreferencesAction } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import { ReceiptPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import { redirect } from "../../../common/utils/NavigationHelpers";
import { updateCustomerProfilePreferences } from "../../../api/preferences/CustomerProfilePreferencesApi";
import { validateEmail } from "./validation/validation";
import {
  createCustomerProfileEmailContact,
  updateCustomerProfileEmailContact,
  deleteCustomerProfileEmailContact,
} from "../../../api/contacts/CustomerProfileContactsApi";
import configuration from "../../../config/Configuration";
import {
  onlineContent,
  email,
  instoreContent,
  paper,
  digitalReceipts,
  preferredemail,
  getBrandSpecificDescriptor,
  generateModeDescription,
  mapPreferences,
} from "../Utils/PreferencesUtils";
import { getCustomerProfileAction } from "../../../store/customer-profile/CustomerProfileActions";
import { useIsBusinessCustomer } from "../Utils/CustomerUtils";

type FormData = {
  email: string;
};

const ReceiptPreferencesPage = () => {
  const history = useHistory();
  const [editablePreferences, setEditablePreferences] = useState<
    ReceiptPreference[]
  >([]);

  const [editableInstorePreferences, setEditableInstorePreferences] = useState<
    ReceiptPreference[]
  >([]);
  const [isOnlinePreferencesChanged, setOnlinePreferencesStatus] =
    useState<boolean>(false);
  const [isInstorePreferencesChanged, setInstorePreferencesStatus] =
    useState<boolean>(false);

  const [updatePreferencesSuccess, setUpdatePreferencesSuccess] =
    useState<boolean>(false);
  const [updatePreferencesFailed, setUpdatePreferencesFailed] =
    useState<boolean>(false);
  const { brand } = useParams<{ brand?: string }>();
  const dispatch = useDispatch();
  const {
    receiptPreferences,
    isLoadingPreferences,
    secondaryEmail,
    isShowingSecondaryEmailSection,
  } = useSelector((state: RootState) => state.customerProfilePreferences);

  const [isOnlineShoppingEmailSelected, setOnlineShoppingEmailState] =
    useState<boolean>(false);

  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const isBusinessCustomer = useIsBusinessCustomer();

  const showLoader = isLoadingPreferences;

  useEffect(() => {
    setEditablePreferences(
      receiptPreferences.filter(
        (pref) =>
          pref.content === onlineContent &&
          pref.brand === brand &&
          pref.mode === email
      )
    );

    setEditableInstorePreferences(
      receiptPreferences.filter(
        (pref) =>
          pref.brand === brand &&
          pref.content === instoreContent &&
          pref.mode === paper
      )
    );
  }, [receiptPreferences, brand]);

  useEffect(() => {
    dispatch(getCustomerProfilePreferencesAction());
    if (customerProfileType === "") {
      dispatch(getCustomerProfileAction());
    }
  }, [dispatch, customerProfileType]);

  useEffect(() => {
    setOnlineShoppingEmailState(isShowingSecondaryEmailSection);
  }, [isShowingSecondaryEmailSection]);

  const changeHandler = (pref: ReceiptPreference) => {
    const clonedEditablePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(editablePreferences)
    );
    const targetItemIndex = clonedEditablePreferences.findIndex(
      (i) => i.name === pref.name
    );
    if (targetItemIndex !== -1) {
      clonedEditablePreferences[targetItemIndex].value = !pref.value;
      setEditablePreferences(clonedEditablePreferences);
      setOnlinePreferencesStatus(true);
    }

    setOnlineShoppingEmailState(!pref.value);
  };

  const changeInstoreHandler = (pref: ReceiptPreference) => {
    const clonedEditableInstorePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(editableInstorePreferences)
    );
    const targetItemIndex = clonedEditableInstorePreferences.findIndex(
      (i) => i.name === pref.name
    );
    if (targetItemIndex !== -1) {
      clonedEditableInstorePreferences[targetItemIndex].value = !pref.value;
      setEditableInstorePreferences(clonedEditableInstorePreferences);
      setInstorePreferencesStatus(true);
    }
  };

  const handleSuccessOrErrorClick = () => {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  };

  const submitChangedPreferences = async (data: FormData) => {
    const payload = mapPreferences([
      ...(isOnlinePreferencesChanged ? editablePreferences : []),
      ...(isInstorePreferencesChanged ? editableInstorePreferences : []),
    ]);

    let isSecondaryEmailChanged = secondaryEmail.emailValue !== data.email;
    let isSecondaryEmailAlreadyExist = secondaryEmail.id !== "";

    let isContactDeleted = false;

    if (isSecondaryEmailChanged && isSecondaryEmailAlreadyExist) {
      if (data.email) {
        await updateCustomerProfileEmailContact(secondaryEmail.id, {
          Email: data.email,
        });
      } else {
        isContactDeleted = true;

        payload.push({
          group: digitalReceipts,
          name: preferredemail,
          value: "",
        });
      }
    }

    if (isSecondaryEmailChanged && !isSecondaryEmailAlreadyExist) {
      if (data.email) {
        const emailCreateResult = await createCustomerProfileEmailContact({
          Email: data.email,
        });

        payload.push({
          group: digitalReceipts,
          name: preferredemail,
          value: emailCreateResult.id.toString(),
        });
      }
    }

    let res = true;

    if (payload.length > 0) {
      res = await updateCustomerProfilePreferences(payload);

      if (res && isContactDeleted) {
        await deleteCustomerProfileEmailContact(secondaryEmail.id);
      }
    }

    if (res) {
      setUpdatePreferencesSuccess(true);
      setUpdatePreferencesFailed(false);
    } else {
      setUpdatePreferencesSuccess(false);
      setUpdatePreferencesFailed(true);
    }
  };

  const updateSuccessMessage = (
    <>
      <IconCircle decorative={true} />
      <CenteredHeader tabIndex={-1}>You're all set!</CenteredHeader>
      <CenteredParagraph>
        Your receipt preferences have been updated.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const updateErrorMessage = (
    <>
      <IconCircle decorative={true} hasError={true} />
      <CenteredHeader hasError={true} tabIndex={-1}>
        Receipt preferences not changed
      </CenteredHeader>
      <CenteredParagraph>
        An error has occurred. Your receipt preferences have not been updated.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const secondaryEmailInput = (
    <InputFieldNew
      id="email"
      type="email"
      aria-invalid={!!errors.email}
      aria-describedby="email-error information-collection"
      autoComplete="email"
      data-testid="email-input"
      defaultValue={secondaryEmail.emailValue}
      {...register("email", {
        required: false,
        validate: validateEmail,
      })}
      hasError={!!errors.email}
      descriptionText="For example, john.smith@gmail.com"
      errorText={
        errors.email && errors.email.message
          ? errors.email.message
          : "For example, john.smith@gmail.com"
      }
    />
  );

  return (
    <Layout>
      <SubPageHeader>
        {`Manage my ${getBrandSpecificDescriptor(brand)} receipt preferences`}
      </SubPageHeader>
      <PageLoader isShown={showLoader} />
      {!showLoader && (
        <ReceiptPreferencesContainer>
          {updatePreferencesSuccess &&
            !updatePreferencesFailed &&
            updateSuccessMessage}
          {!updatePreferencesSuccess &&
            updatePreferencesFailed &&
            updateErrorMessage}
          <form
            onSubmit={handleSubmit(submitChangedPreferences)}
            data-testid="manage-my-receipt-preferences-form"
            noValidate
          >
            {!(updatePreferencesSuccess || updatePreferencesFailed) && (
              <>
                <ReceiptPreferencesDescriptionBold>
                  Instore shopping
                </ReceiptPreferencesDescriptionBold>

                <ReceiptPreferencesDescription>
                  Tell us how you'd like to get receipts for instore shops
                </ReceiptPreferencesDescription>

                {editableInstorePreferences &&
                  editableInstorePreferences.length &&
                  editableInstorePreferences.length > 0 &&
                  editableInstorePreferences.map((pref) => (
                    <ReceiptPreferencesCheckbox
                      key={pref.name}
                      id={pref.name}
                      name={pref.name}
                      testid={`test-${pref.name}`}
                      label={generateModeDescription(pref.mode)}
                      hasError={false}
                      checked={pref.value}
                      onChange={() => {
                        changeInstoreHandler(pref);
                      }}
                    />
                  ))}

                <ReceiptPreferencesDescription>
                  Shop and scan your Coles barcode or a Flybuys card linked to
                  your Coles account in-store to view your receipts on the Coles
                  app.
                </ReceiptPreferencesDescription>

                <Divider />
                <ReceiptPreferencesDescriptionBold>
                  Online shopping
                </ReceiptPreferencesDescriptionBold>
                {isBusinessCustomer && (
                  <ReceiptPreferencesDescription>
                    Tell us how you'd like to get receipts for online orders
                  </ReceiptPreferencesDescription>
                )}
                {isBusinessCustomer ? (
                  <>
                    {editablePreferences &&
                      editablePreferences.length &&
                      editablePreferences.length > 0 &&
                      editablePreferences.map((pref) => (
                        <ReceiptPreferencesCheckbox
                          key={pref.name}
                          id={pref.name}
                          name={pref.name}
                          testid={`test-${pref.name}`}
                          label={generateModeDescription(pref.mode)}
                          hasError={false}
                          checked={pref.value}
                          onChange={() => {
                            changeHandler(pref);
                          }}
                        />
                      ))}
                  </>
                ) : (
                  <ReceiptPreferencesDescription>
                    We're working on it. You'll see online shopping receipt
                    preferences here soon.
                  </ReceiptPreferencesDescription>
                )}
                <ReceiptPreferencesDescriptionBoldLvl2>
                  Receipts saved in your account
                </ReceiptPreferencesDescriptionBoldLvl2>
                <ReceiptPreferencesDescription>
                  Find receipts for online orders in <b>Past orders</b> online
                  and in <b>Payment history</b> in the app.
                </ReceiptPreferencesDescription>
                {isBusinessCustomer && isOnlineShoppingEmailSelected && (
                  <>
                    <Divider />
                    <ReceiptPreferencesDescriptionBold>
                      Send a copy of your receipt
                    </ReceiptPreferencesDescriptionBold>
                    <ReceiptPreferencesDescription>
                      Send your online order receipts to another email address.
                    </ReceiptPreferencesDescription>
                    {secondaryEmailInput}
                  </>
                )}
                <ReceiptPreferencesButtons>
                  <ThemedButton
                    data-testid="save-button"
                    type="submit"
                    aria-label="Save preference for receipt communications"
                  >
                    Save preferences
                  </ThemedButton>
                  <CancelLink tabIndex={0} href={getReturnToLink()}>
                    Cancel
                  </CancelLink>
                </ReceiptPreferencesButtons>
              </>
            )}
          </form>
        </ReceiptPreferencesContainer>
      )}
      <AppFooter />
    </Layout>
  );
};

export default ReceiptPreferencesPage;
